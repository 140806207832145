import React, { useState } from 'react';
import { GithubIcon, LinkedinIcon, Mail, Award, Book, Code, Flame, Briefcase } from 'lucide-react';

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState('awards');
  const [isChatOpen, setChatOpen] = useState(false);
  const toggleChat = () => {
    setChatOpen(!isChatOpen);
};
const research = [
  {
    title: "Deep Learning Researcher",
    description: "Created a Gated Recurrent Unit (GRU) from scratch using numpy, modifying its architecture to achieve a 10% improvement in performance over the existing implementation. Invented and evaluated Recurrent Symbolic Networks architecture to tackle the vanishing gradient problem in RNNs, enhancing performance by 20% and interpretability for sequential data analysis.",
    conference: "Caldwell University Stem Summer Research, May 2024 - Present"
  },
  {
    title: "Machine Learning Researcher",
    description: "Trained a Neural Network architecture model using TensorFlow’s Keras library, comprising 550k parameters, aimed at detecting plagiarism in academic assignments, achieving a 95% accuracy rate. Collaborated with professors and fellow researchers to refine research methodologies, analyze results, and publish findings in the 28th annual conference of the Consortium for Computing Sciences.",
    conference: "28th annual conference of the Consortium for Computing Sciences, Jan 2024 - Apr 2024"
  }
];

  

  const awards = [
    {
      title: "Winner - HackPrinceton Fall 2024 Hackathon",
      project: "Crystal Math",
      description: "Making Mathematics Visual, Intuitive, and Alive Through AI-Powered Animations",
      url: "https://dorahacks.io/buidl/19465"
    },
    {
      title: "Winner - HopHacks Fall 2024 Hackathon",
      project: "E-Graveyard",
      description: "Built an innovative AI platform for digital conversations with deceased loved ones",
      url: "https://devpost.com/software/e-graveyard-where-your-loved-ones-are-remembered-forever"
    },
    {
      title: "Winner - HackRU Fall 2024 Hackathon",
      project: "ODG Vision",
      description: "Developed prototype for assisting visually imparied person",
      url: "https://devpost.com/software/odg-vision"
    },
    {
      title: "Winner - HackNJIT Fall 2024 Hackathon",
      project: "Punkify",
      description: "Developed website for making therapy more accessible and engaging",
      url: "https://devpost.com/software/punkify"
    }
    
  ];

  const projects = [
    {
      title: "Good Fone",
      tech: "Raspberry Pi, Django, React.js, PostgreSQL",
      description: "Designed and developed a secure, web-based AI-powered phone platform aimed at providing a controlled mobile environment for children, with plans to transition to a Chrome OS-like operating system. Created AI applications using Langchain for RAG chains, leveraging OpenAI and Vertex AI to build custom AI for Finance Help, Homework, Persona, and Notes with accurate, reliable functionalities."
    },
    {
      title: "E-Graveyard",
      tech: "Django, React.js, PostgreSQL, Redis, Celery",
      description: "Built an innovative platform enabling users to engage in digital conversations with AI representations of deceased loved ones or inspirational figures, securing 2nd place at HopHacks Hackathon. Implemented voice cloning through Eleven Labs API, custom vector storage with pg-vector in PostgreSQL, and RAG architecture to deliver contextually relevant responses."
    },
    {
      title: "Professor AI",
      tech: "C#, Typescript, SQL, NLP, Azure",
      description: "Developed a professor-styled chatbot using Azure API and C# for backend development, React.js with TypeScript for frontend, and SQL for database management, achieving top 5 placement in the Microsoft AI Hackathon. Engineered natural language processing (NLP) using Python's NLTK library to improve the chatbot's understanding and response accuracy, enhancing user interaction and satisfaction by 25%."
    },
    {
      title: "Job Tracking App",
      tech: "Postgres, Redis, React.js, Django, Celery",
      description: "Developed a job listing web application that utilized email parsing with imaplib to automatically identify and leverage AI techniques to analyze email content and determine if it was related to a job application. Implemented the Django framework for the backend, PostgreSQL for the database, and React.js for a seamless user interface. Integrated Celery with Redis to offload time-consuming tasks from the Django application and execute them on a scheduled basis, improving overall system performance."
    },
    {
      title: "Plagiarism Detector",
      tech: "Tensorflow, Keras, Python, Matplotlib, Streamlit",
      description: "Trained a Neural Network architecture model using TensorFlow’s Keras library, comprising 550k parameters, aimed at detecting plagiarism in academic assignments, achieving a 95% accuracy rate. Collaborated with professors and fellow researchers to refine research methodologies, analyze results, and publish findings in the 28th annual conference of the Consortium for Computing Sciences."
    },
    {
      title: "ODG Vision",
      tech: "Python, OpenCV, Scikit-Learn, Raspberry Pi, Tensorflow",
      description: "Developed a prototype to assist visually impaired individuals by implementing YOLO object detection for recognizing various objects. Customized a Haar Cascade model to identify and train faces of friends and family. Utilized the Raspberry Pi Camera V2 module for testing and the Raspberry Pi for processing, running on a headless Ubuntu server. Integrated a speech-to-speech AI to enhance user interaction."
    },
    {
      title: "YouTube Content Creator",
      tech: "VB.NET, Video Production, Digital Literacy",
      description: "Developed and managed an educational YouTube channel, specializing in tutorials for virus removal, software development, and programming concepts. Produced and curated a library of 35 videos, gained a following of 498 subscribers, and achieved over 58,000 total views. Utilized VB.NET to develop a comprehensive tic-tac-toe game tutorial series, while simultaneously providing detailed guides on data recovery and system protection against ransomware to promote digital literacy and online safety."
    }
  ];
  

  const experience = [
    {
      title: "Software Developer Intern",
      company: "Digital Coach",
      period: "Oct 2024 - Present",
      description: "Developed an AI-driven e-learning application using Next.js, React, and Firebase, enhancing user engagement by 20% through interactive, real-time sentiment analysis in mock interviews. Integrated machine learning models in Python and TensorFlow for response analysis, delivering personalized scores and feedback that boosted user engagement by 25% and increased success rates by 15%."
    },
    {
      title: "AI Engineer Intern",
      company: "Radical AI",
      period: "Jun 2024 - Aug 2024",
      description: "Led the development and deployment of AI solutions using OpenAI Assistant API and Google Gemini, achieving a 30% increase in system capabilities. Managed end-to-end data pipeline processes, optimizing data quality for generative AI models and resulting in a 25% improvement in model performance and reliability. Spearheaded prompt engineering and performance testing, resulting in a 20% increase in technological advancements."
    },
    {
      title: "Machine Learning Researcher",
      company: "Caldwell University",
      period: "May 2024 - Aug 2024",
      description: "Invented and evaluated Recurrent Symbolic Networks architecture to tackle the vanishing gradient problem in RNNs, enhancing performance by 20% and interpretability for sequential data analysis. Collaborated with professors and fellow researchers to refine methodologies, analyze results, and publish findings at the 28th annual conference of the Consortium for Computing Sciences."
    },
    {
      title: "Software Engineer Intern",
      company: "Wicked Pay",
      period: "Jan 2024 - May 2024",
      description: "Created a batch-processing ETL pipeline that improved throughput by 30% and scalability by 25%, using Apache Airflow and Heroku. Engineered a seamless data transfer process leveraging REST API in C#, .Net Framework, JavaScript, and React.js for DOM manipulation, driving a 50% surge in user engagement. Deployed data pipeline to automate application deployment using Netlify and GitHub, improving efficiency and reducing manual workload."
    },
    {
      title: "Computer Science Tutor",
      company: "Caldwell University",
      period: "Oct 2023 - Present",
      description: "Mentored over 200 students in Object-Oriented Programming, Machine Learning, and Data Structures, implementing personalized tutoring techniques that raised average student grades by 30%. Optimized the learning environment by streamlining code review and introducing industry-standard tools such as Git and Docker, resulting in a 30% decrease in coding errors."
    },
    {
      title: "Software Engineering Intern",
      company: "The Vidhwan",
      period: "Jan 2021 - Dec 2022",
      description: "Engineered and implemented custom components for community platforms using JavaScript, HTML, CSS, and PHP (Laravel), leading to a 20% upswing in user population. Set up a payment system from Esewa and Khalti using curl API, facilitating over 1000 secure transactions per month."
    }
  ];
  

  const renderSection = () => {
    switch (activeSection) {
      case 'about':
        return (
          <div className="space-y-6">
            <h2 className="text-3xl font-bold">About Me</h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              Computer Science student at Caldwell University with strong focus on AI/ML and software development.
              Experience in building AI-powered applications, conducting machine learning research, and developing
              full-stack applications.
            </p>
            <div className="flex space-x-10 mt-8">
              <a href="https://www.linkedin.com/in/khagendrakhatri/" className="text-blue-600 hover:text-blue-800 transition-colors">
                <LinkedinIcon className="w-9 h-9" />
              </a>
              <a href="mailto:realkhagendra@gmail.com" className="text-gray-600 hover:text-gray-800 transition-colors">
                <Mail className="w-9 h-9" />
              </a>
              <a href="https://github.com" className="text-gray-800 hover:text-black transition-colors">
                <GithubIcon className="w-9 h-9" />
              </a>
            </div>
          </div>
        );
      case 'research':
        return (
          <div className="space-y-8">
            <h2 className="text-3xl font-bold">Research</h2>
            <div className="space-y-6">
              {research.map((item, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
                  <h3 className="text-2xl font-semibold">{item.title}</h3>
                  <p className="text-gray-600 mt-3 leading-relaxed">{item.description}</p>
                  <p className="text-sm text-blue-600 mt-4">Published in: {item.conference}</p>
                </div>
              ))}
            </div>
          </div>
        );
      case 'awards':
        return (
          <div className="space-y-8">
            <h2 className="text-3xl font-bold">Awards & Recognition</h2>
            <div className="space-y-6">
              {awards.map((award, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
                  <h3 className="text-2xl font-semibold">{award.title}</h3>
                  <p className="text-blue-600 mt-2">{award.project}</p>
                  <p className="text-gray-600 mt-3 leading-relaxed">{award.description}</p>
                  {award.url && (
            <a
              href={award.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline mt-3 inline-block"
            >
              Visit the project
            </a>
          )}
                </div>
              ))}
            </div>
          </div>
        );
      case 'projects':
        return (
          <div className="space-y-8">
            <h2 className="text-3xl font-bold">Projects</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {projects.map((project, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
                  <h3 className="text-2xl font-semibold">{project.title}</h3>
                  <p className="text-sm text-blue-600 mt-2">{project.tech}</p>
                  <p className="text-gray-600 mt-3 leading-relaxed">{project.description}</p>
                </div>
              ))}
            </div>
          </div>
        );
      case 'experience':
        return (
          <div className="space-y-8">
            <h2 className="text-3xl font-bold">Experience</h2>
            <div className="space-y-6">
              {experience.map((exp, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
                  <h3 className="text-2xl font-semibold">{exp.title}</h3>
                  <p className="text-blue-600 mt-1">{exp.company}</p>
                  <p className="text-sm text-gray-500 mt-1">{exp.period}</p>
                  <p className="text-gray-600 mt-3 leading-relaxed">{exp.description}</p>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 " style={{ marginLeft: '13px' }}>
      <nav className="bg-white shadow-sm py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div className="flex-shrink-0">
              <h1 className="text-4xl font-bold text-gray-900">Khagendra Khatri (K-Gen)</h1>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row gap-12">
          <aside className="md:w-72">
            <nav className="space-y-3">
            <button
                onClick={() => setActiveSection('experience')}
                className={`w-full text-left px-6 py-3 rounded-xl flex items-center space-x-3 transition-colors ${
                  activeSection === 'experience' ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                }`}
              >
                <Briefcase className="w-5 h-5" />
                <span className="font-medium">Experience</span>
              </button>
              <button
                onClick={() => setActiveSection('research')}
                className={`w-full text-left px-6 py-3 rounded-xl flex items-center space-x-3 transition-colors ${
                  activeSection === 'research' ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                }`}
              >
                <Flame className="w-5 h-5" />
                <span className="font-medium">Research</span>
              </button>
              <button
                onClick={() => setActiveSection('awards')}
                className={`w-full text-left px-6 py-3 rounded-xl flex items-center space-x-3 transition-colors ${
                  activeSection === 'awards' ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                }`}
              >
                <Award className="w-5 h-5" />
                <span className="font-medium">Awards</span>
              </button>
              <button
                onClick={() => setActiveSection('projects')}
                className={`w-full text-left px-6 py-3 rounded-xl flex items-center space-x-3 transition-colors ${
                  activeSection === 'projects' ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                }`}
              >
                <Code className="w-5 h-5" />
                <span className="font-medium">Projects</span>
              </button>

              <button
                onClick={() => setActiveSection('about')}
                className={`w-full text-left px-6 py-3 rounded-xl flex items-center space-x-3 transition-colors ${
                  activeSection === 'about' ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                }`}
              >
                <Code className="w-5 h-5" />
                <span className="font-medium">About</span>
              </button>
            </nav>
          </aside>
          
          <div className="flex-1">
            {renderSection()}
             {/* Chat Button */}
             <button 
                onClick={toggleChat}
                className="fixed right-5 bottom-5 bg-blue-600 text-white rounded-full p-3 shadow-lg hover:bg-blue-700 transition"
            >
                Ask K-gen Bot
            </button>

            {/* Chat Box */}
            {isChatOpen && (
                <div className="fixed right-5 bottom-16 bg-white shadow-lg rounded-lg p-4 w-80">
                    <h2 className="text-lg font-bold">Chat</h2>
                    <div className="h-60 overflow-y-auto">
                        {/* Chat messages will go here */}
                    </div>
                    <input type="text" placeholder="Type a message..." className="w-full border rounded px-2 py-1 mt-2" />
                </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Portfolio;